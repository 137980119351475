import { defineStore } from 'pinia';
import { baseUrl, ossPath } from '@/utils/http/axiosKeyConfig';

export const mainStore = defineStore('main', {
  state: (): {
    axiosBaseURL: string;
    ossURL: string;
    urlMap: any;
    bodyCode: string;
  } => {
    return {
      axiosBaseURL: baseUrl,
      ossURL: ossPath,
      urlMap: {},
      bodyCode: 'hx',
    };
  },

  getters: {},

  actions: {},
});
