export function baseMethods() {
  // 微信和支付宝
  function is_weixn() {
    const ua = navigator.userAgent.toLowerCase();
    const micromessenger = ua.match(/MicroMessenger/i);
    const alipayclient = ua.match(/AlipayClient/i);
    if (micromessenger && micromessenger.length) {
      return true;
    } else if (alipayclient && alipayclient.length) {
      return true;
    } else {
      return false;
    }
  }
  function isPhone() {
    const u = navigator.userAgent,
      app = navigator.appVersion;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
      //这个是安卓操作系统
      return 'Android';
    }
    if (isIOS) {
      return 'IOS';
      //这个是ios操作系统
    }
    return 'PC';
  }

  /**
   *金钱格式化
   *{param:number} s 金额
   *{param:number } n 保留几位小数
   *eg:this.fmoney(1342684.316,2) // 1,342,684.32
   */
  function formatMoney(s: string, n: number) {
    s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
    const l = s.split('.')[0].split('').reverse();
    let t = '';
    for (let index = 0; index < l.length; index++) {
      t += l[index] + ((index + 1) % 3 == 0 && index + 1 != l.length ? ',' : '');
    }
    let result = t.split('').reverse().join('');
    if (n) {
      const r = s.split('.')[1];
      result += '.' + r;
    }
    return result;
  }

  /**
   * 数字转整数 如 100000 转为10万
   * @param {需要转化的数} num
   * @param {需要保留的小数位数} point
   */
  function formatMoneyUnit(num: number, point: number) {
    const numStr = num.toString();
    //大于8位数是亿
    if (numStr.length > 8) {
      const decimal = numStr.substring(numStr.length - 8, numStr.length - 8 + point);
      return parseFloat(parseInt(String(num / 100000000)) + '.' + decimal) + '亿';
    }
    //大于6位数是十万 (以10W分割 10W以下全部显示)
    else if (numStr.length > 4) {
      const decimal = numStr.substring(numStr.length - 4, numStr.length - 4 + point);
      return parseFloat(parseInt(String(num / 10000)) + '.' + decimal) + '万';
    }
    return numStr;
  }

  return {
    formatMoneyUnit,
    formatMoney,
    is_weixn,
    isPhone,
  };
}
