import { defineStore } from 'pinia';
import { AgreementResult } from '@/api/base/type';

export const useUserStore = defineStore('user', () => {
  const userCity = ref('');
  function setCity(city: string) {
    userCity.value = city;
  }

  const axiosBaseURL = ref('');
  function setAxiosBaseURL(url: string) {
    axiosBaseURL.value = url;
  }

  const agreementURLArr = ref<AgreementResult>();
  function setAgreementURL(url: AgreementResult) {
    agreementURLArr.value = url;
  }
  return {
    userCity,
    setCity,
    axiosBaseURL,
    setAxiosBaseURL,
    agreementURLArr,
    setAgreementURL,
  };
});
