export const h5Router = [
  {
    path: '/gjjraq',
    name: 'gjjraq',
    meta: {
      title: '国家金融安全',
    },
    component: () => import('@/pages/h5/one.vue'),
  },
  {
    path: '/helpCenter',
    name: 'helpCenter',
    meta: {
      title: '帮助中心',
    },
    component: () => import('@/pages/h5/helpCenter.vue'),
  },
  {
    path: '/feedback',
    name: 'feedback',
    meta: {
      title: '意见反馈',
    },
    component: () => import('@/pages/h5/feedback.vue'),
  },
];
