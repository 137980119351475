export const guideRouter = [
  {
    path: '/guideOne',
    name: 'guideOne',
    meta: {
      title: '下载页',
    },
    component: () => import('@/pages/guide/one.vue'),
  },
];
