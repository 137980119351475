import App from './App.vue';
import router from '@/router';
import 'amfe-flexible';
import 'vant/lib/index.css';
import '@/styles/base.less';
import Vconsole from 'vconsole';
import { createPinia } from 'pinia';
import { storage } from '@/utils';

const store = createPinia();

if (process.env.NODE_ENV === 'testing' || storage.getItem('debug', 2) === '1') {
  new Vconsole();
}
const app = createApp(App);

app.use(router);
app.use(store);

app.mount('#app');
