import http from '@/utils/http';
import { IpTypes, ConfigQuery, AgreementResult } from './type';

// IP解析
export const ipAddr = (): Promise<IpTypes> => http.get<IpTypes>(`/customer/ipAnalysis.e`);

// 域名配置查询
export const configQuery = (data: { type: number }): Promise<ConfigQuery> =>
  http.get<ConfigQuery>(`/open/config/query.e`, data);

export const userAgreementResult = (data: { packageType: number }): Promise<AgreementResult> =>
  http.get<AgreementResult>(`/customer/userAgreementResultNew.e`, data);

export const uploadFile = (data: { file: any; ossEnum: string }): Promise<any> => {
  return http.upload<any>(`/customer/upload/image`, data);
};
