let SIGNKEY = '';
let ossPath = '';
let baseUrl = '';

/*
 * TODO 需要更换接口、域名时需要修改的地方
 * 本项目还有public内的协议，可全局替换域名
 * */
switch (import.meta.env.VITE_USER_NODE_ENV) {
  case 'production':
    baseUrl = 'https://gw.xiaoyiqb.cn/';
    ossPath = 'https://oss.xiaoyiqb.cn/';
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
    break;
  case 'test':
    baseUrl = 'https://test-gw.xiaoyiqb.cn/';
    ossPath = 'https://test-oss.xiaoyiqb.cn/';
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
    break;
  default:
    // baseUrl = 'https://gw.xiaoyiqb.cn/';
    baseUrl = 'http://192.168.110.120:11000/';
    ossPath = 'https://oss.xiaoyiqb.cn/';
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
}
export { SIGNKEY, ossPath, baseUrl };
