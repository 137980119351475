export const wapRouter = [
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '金融服务',
    },
    component: () => import('@/pages/wap/home.vue'),
  },
  {
    path: '/loanProduct',
    name: 'loanProduct',
    meta: {
      title: '详情',
    },
    component: () => import('@/pages/wap/loanProduct.vue'),
  },
  {
    path: '/ocrPage',
    name: 'ocrPage',
    meta: {
      title: '个人认证',
    },
    component: () => import('@/pages/ocr/ocrPage.vue'),
  },
  {
    path: '/ocrName',
    name: 'ocrName',
    meta: {
      title: '个人认证',
    },
    component: () => import('@/pages/ocr/ocrName.vue'),
  },
  {
    path: '/ocrResult',
    name: 'ocrResult',
    meta: {
      title: '个人认证',
    },
    component: () => import('@/pages/ocr/ocrResult.vue'),
  },
];
